import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import App from './components/App'
import Contacts from './components/Contacts'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Biography from './components/Biography';
import Certificates from './components/Certificates';
import ScientificActivity from './components/ScientificActivity';
import Video from './components/Video';
import GalleryPhoto from './components/GalleryPhoto';
import CommonInfo from './components/CommonInfo';
import Operation from './components/Operation';
import Nooperation from './components/Nooperation';
import Reviews from './components/Reviews';
import What_i_treat from './components/Main/what_i_treat';

const StickyElement = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const objToStick = document.getElementById('objToStick');
      const topOfObjToStick = objToStick.offsetTop;
      const windowScroll = window.pageYOffset;

      if (windowScroll > topOfObjToStick) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (


    <div className={`askme ${isSticky ? 'topWindow' : ''}`} id="objToStick">
      <p>Задайте мені питання<br></br> в телеграмі</p>
      <a href="https://t.me/doctor_zheltov" target="_blank" rel="noreferrer">

        <img src="https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/Telegram_Messenger.png"  alt='telegram' />
      </a>
    </div>



  );
};


ReactDOM.render(
  <Router>
    {/* Render the StickyElement component outside of <Routes> */}
    <StickyElement />

    <Routes>
      {/* Define the root route to render the App component */}
      <Route path="/" element={<App />}/>
        {/* Define child routes here */}
        <Route path="Contacts" element={<Contacts />} />
        <Route path="reviews" element={<Reviews />} />
        <Route path="aboutMe/biography" element={<Biography />} />
        <Route path="aboutMe/mycertificates" element={<Certificates />} />
        <Route path="aboutMe/scientificActivity" element={<ScientificActivity />} />
        <Route path="patient/commoninfo" element={<CommonInfo />} />
        <Route path="patient/operation" element={<Operation />} />
        <Route path="patient/nooperation" element={<Nooperation />} />
        <Route path="myworks/photo" element={<GalleryPhoto />} />
        <Route path="myworks/video" element={<Video />} />
        <Route path="main/what_i_treat" element={<What_i_treat />} />
  
    </Routes>
  </Router>,
  document.getElementById('root')
);