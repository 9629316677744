import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './styleInteresting.css'
import { Link} from 'react-router-dom'

function Interesting() {
    return (
        <div className="interesting">
                <h2 className="text-center">Це буде цікаво</h2>
                <div className="row text-center" style={{ margin: '5em 0' }}>

                    <div className="col">
                    <Link to="/aboutMe/biography"><button className="rounded-circle lead">Біографія</button> </Link>
                    </div>
                    <div className="col">
                    <Link to="/aboutMe/mycertificates"><button className="rounded-circle lead">Мої сертифікати</button></Link>
                    </div>
                    <div className="col">
                        <Link to="/reviews"><button className="rounded-circle lead">Відгуки</button></Link>
                    </div>
            </div>
        </div>


    )
}
export default Interesting