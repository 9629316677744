import React, { Component } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import Bottom from '../Bottom'
import './style.css'
import PhotoGrid from "react-photo-feed";
import "react-photo-feed/library/style.css";
import { Helmet } from 'react-helmet';

const demoPhotos = [
    {
        id: 1, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7280.jpg',
        bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7280.jpg'
    },
    {
        id: 2, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7007.jpg',
        bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7007.jpg'
    },
    {
        id: 3, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7009.jpg',
        bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7009.jpg'
    },
    {
        id: 4, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7010.jpg',
        bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7010.jpg',
    },
    {
        id: 5, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7436.jpg',
        bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7436.jpg',
    },
    {
        id: 6, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7437.jpg',
        bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7437.jpg',
    },
    {
        id: 7, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/0-02-0a-e863df8655d4c43fc15dde731e626c7275196b5c3a079cc94a7e9098e7434300_1c6d9bbdd2fa6a.jpg',
        bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/0-02-0a-e863df8655d4c43fc15dde731e626c7275196b5c3a079cc94a7e9098e7434300_1c6d9bbdd2fa6a.jpg',
    }
];

class ScientificActivity extends Component {
    
    render() {
        return (
            <div>
             <Helmet>
               <title>Наукова діяльність - Андрій Желтов, ЛОР лікар</title>
               <meta name="description" content="Список наукових робіт, опублікованих за темою дисертації лор лікаря, кандидата медичних наук Желтова Андрія " />
            </Helmet>
                <TopLine />
                <PhotoLine />
                <Menu />
                <div className="scientific-page">
                    <div className="container">
                        <h3 className="text-center text-uppercase">Моя наукова діяльність</h3><br></br>
                        <PhotoGrid columns={3} photos={demoPhotos} />
                    </div>
                </div>
                <div className="biography-page">
                    <h3 className="text-center text-uppercase mt-3">Список наукових робіт, опублікованих за темою дисертації</h3><br></br>
                    <ul className="list">
                        <li>
                            Кокоркин Д. Н. Опыт использования комбинированного назального спрея «Мералис» при лечении секреторных отитов / Д. Н. Кокоркин, А. Я. Желтов // Современная педиатрия. - 2013. - № 3 (51). - С. 66-69.
                        </li>
                        <li>
                            Гусаков А. Д. Особенности хирургического лечения патологии лимфоидной ткани носоглотки у детей с экссудативным средним отитом /   А. Д. Гусаков, А. Я. Желтов // Российская оториноларингология. – 2013. -    № 2 (63). – С. 144 – 148
                        </li>
                        <li>
                            Кокоркин Д. Н. Наш опыт использования назального спрея «Мералис» в лечении детей с острыми и хроническими средними отитами / Д. Н. Кокоркин, В. В. Скоробогатый, А. Я. Желтов // Современная педиатрия. - 2013. - № 7 (55). - С. 22-26.
                        </li>
                        <li>Овчаренко Л. С. Нейрорегуляторные, морфологические особенности слизистой оболочки полости носа у детей с гиперплазией лимфоидной ткани глотки / Л. С. Овчаренко, В. Ю. Ткаченко, А. Я. Желтов // Педиатрия. Восточная Европа. – 2015. - №4 (12). – С. 118-127.
                        </li>
                        <li>Желтов А. Я. Оценка характера вегетативной иннервации слуховой трубы по данным микроциркуляции крови в слизистой оболочке нижних носовых раковин / А. Я. Желтов, В. А. Каширин, А. А. Гусакова // Ринология. – 2016. – № 2. – С. 30 – 36.
                        </li>
                        <li>Гусаков А. Д. Сравнительная эффективность методов хирургического лечения аденоидных вегетаций у детей с экссудативными средними отитами / А. Д. Гусаков, А. Я. Желтов, Д. Н. Кокоркин // Современная педиатрия. - 2016. - № 2 (74). - С. 151-154.
                        </li>
                        <li>Желтов А. Я. Дисбаланс вегетативной иннервации в патогенезе экссудативного среднего отита / А. Я. Желтов, В. А. Каширин, А. Д. Гусаков // Ринология. – 2016. – № 3-4. – С. 45 – 53.
                        </li>
                        <li>Желтов А. Я. Отдаленные результаты хирургического лечения детей, страдающих патологией глоточной миндалины и секреторным средним отитом / А. Я. Желтов, А. Д. Гусаков, А. А.  Гусакова // Журнал вушних, носових і горлових хвороб. - 2017. - № 6. - С. 36 - 42.
                        </li>
                        <li>Желтов А. Я. Органосохраняющий принцип операций на глоточной миндалине у детей при экссудативных отитах / А. Я. Желтов // Сучасні медичні технології. - 2017. - № 1 (32). - С. 13-17.
                        </li>
                        <li>Желтов А. Я. Особенности микроциркуляции в слизистой оболочке носа у детей с гипертрофией аденоиов и экссудативным средним отитом // А. Я. Желтов, А. Н. Костровский, А. А.  Гусакова // Запорожский медицинский журнал. - 2018. – Том 20, № 6(111). - С. 811 - 816.
                        </li>
                        <li>Выбор метода хирургического лечения заболеваний носоглотки у детей с секреторным отитом / А. Д. Гусаков, А. Я. Желтов, А. Н. Костровский [и др.] // Журнал вушних, носових і горлових хвороб. - 2012. - № 3с. - С. 59.
                        </li>
                        <li>Особенности анатомического строения носоглотки у детей с экссудативным средним отитом / А. Я. Желтов, А. Д. Гусаков, А. Н. Костровский [и др.] // Журнал вушних, носових і горлових хвороб. - 2012. -    № 5с. - С. 57-58.
                        </li>
                        <li>Состояние микроциркуляции крови в слизистой оболочке полости носа у детей с экссудативным средним отитом / А. Я. Желтов, А. Д. Гусаков, А. Н. Костровский [и др.] // Журнал вушних, носових і горлових хвороб. - 2012. - № 5с. - С. 59.
                        </li>
                        <li>Особенности строения носоглотки у детей, болеющих экссудативным средним отитом, в прошлом перенесших аденотомию  / А. Я. Желтов, А. Д. Гусаков, А. Н. Костровский [и др.] // Журнал вушних, носових і горлових хвороб. - 2013. - № 3с. - С. 82-83.
                        </li>
                        <li>К вопросу о причинности экссудативных отитов у детей / А. Я. Желтов, А. Д. Гусаков, Д. Н. Кокоркин [и др.] // Журнал вушних, носових і горлових хвороб. - 2013. - № 5с. - С. 79.
                        </li>
                        <li>Холодноплазменная хирургия носоглотки у детей, болеющих экссудативным средним отитом / А. Я. Желтов, А. Д. Гусаков, В. Ю. Ткаченко [и др.] // Журнал вушних, носових і горлових хвороб. - 2014. -       № 3c. - С. 62.
                        </li>
                        <li>Желтов А. Я. Характеристика микроциркуляции в слизистой оболочке носа у детей с гипертрофией лимфоидной ткани носоглотки страдающих экссудативным средним отитом / А. Я. Желтов, А. Д. Гусаков, А. Н. Костровский // Журнал вушних, носових і горлових хвороб. – 2014. -   № 5с. - С. 46-47.
                        </li>
                        <li>Желтов А. Я. Состояние носоглотки при экссудативном среднем отите в отдаленном периоде после традиционной аденотомии у детей 5-7 лет / А. Я. Желтов, А. Д. Гусаков, В. Ю. Ткаченко // Журнал вушних, носових і горлових хвороб. – 2014. - № 5с. - С. 47-48.
                        </li>
                        <li>Желтов А. Я. Органосохраняющий принцип операций  у детей с гипертрофией глоточной миндалины при экссудативных отитах / А. Я. Желтов, А. Д. Гусаков // Журнал вушних, носових і горлових хвороб. - 2015. - № 5c. - С. 54-55.
                        </li>
                        <li>Желтов А. Я. Отдаленные результаты эндомикрохирургии лимфоидной ткани носоглотки у детей, страдающих экссудативным средним отитом / А.Я. Желтов, А. Д. Гусаков // Журнал вушних, носових і горлових хвороб. - 2016. - № 3c. - С. 49.
                        </li>
                        <li>Желтов А. Я.  Всегда ли гипертрофия глоточной миндалины может быть причиной экссудативного среднего отита / А. Я. Желтов, А. Д. Гусаков // Журнал вушних, носових і горлових хвороб. - 2016. - № 5c. - С. 42-43.
                        </li>
                        <li>Желтов А. Я. Отдаленные результаты хирургии носоглотки у детей, страдающих экссудативным средним отитом / А. Я. Желтов, А. Д. Гусаков, А. А. Гусакова // Журнал вушних, носових і горлових хвороб. – 2017. - №3с. - С. 37.
                        </li>
                        <li>Желтов А. Я. Оценка результатов хирургического лечения носоглотки у детей, страдающих экссудативным средним отитом / А. Я. Желтов // Журнал вушних, носових і горлових хвороб. – 2018. - № 3с. - С. 30.
                        </li>
                        <li>Желтов А. Я. Отдаленные результаты модифицированного способа аденотомии у детей с секреторным отитом / А. Я. Желтов, А. А. Гусакова, А. Н. Костровский // Журнал вушних, носових і горлових хвороб. – 2018. - № 5с. - С. 30.
                        </li>
                        <li>Дифференцированный подход к хирургическому лечению детей страдающих хроническим воспалением глоточной миндалины с учетом эндоскопии носоглотки / А. Я. Желтов, А. Д. Гусаков, В. А. Каширин [и др.] // ХII з’їзд отоларингологів України, 18-20 трав. 2015 р. : матеріали. - Львів, 2015. - С. 44-45.
                        </li>
                        <li>Желтов А. Я. К вопросу об аденотомии как методе лечения детей с заболеваниями носоглотки и сопутствующим экссудативным отитом / А. Я. Желтов, А. Д. Гусаков, А. А. Гусакова // Актуальні питання клінічної медицини : ХІ всеукр. наук.-практ. конф. молодих вчених (Запоріжжя,          27 жовт. 2017 р.). – Запоріжжя, 2017. – С. 22-24.
                        </li>
                        <li>Желтов А. Я. Ближайшие и отдаленные результаты модифицированного способа аденотомии у детей с секреторными отитами / А. Я. Желтов // Актуальні питання дитячої оториноларингології : матеріали ІІ конгресу дитячих оториноларингологів України з міжнародною участю АДОУ (до 100-річчя заснування НМАПО ім. П. Л. Шупика). – Київ, 2018.
                        Патенти.
                        </li>
                        <li>Патент на корисну модель 110200 Україна, МПК (2016.01) А 61В 17/00. Спосіб лікування ексудативного отиту у дітей із захворюваннями лімфоїдної тканини носоглотки / Желтов А.Я., Гусаков О.Д.; заявник та патентовласник ДЗ «ЗМАПО МОЗ України». - № u 2016 04027; заявл. 13.04.16; опубл. 26.09.16, Бюл. № 18.
                        </li>
                        <li>Патент на корисну модель 111481 Україна, МПК (2016.01) А61В 18/00 А61F 11/04 (2006.01). Спосіб тимпаностомії в дітей, хворих на ексудативний отит /  Желтов А.Я., Костровський О.М.; заявник та патентовласник ДЗ «ЗМАПО МОЗ України». - № u 2016 05092; заявл. 10.05.16; опубл. 10.11.16, Бюл. № 21.
                        </li>
                    </ul>
                </div>
                <Bottom />
               
            </div>

        );
    }
}



export default ScientificActivity