import React, { Component } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import Bottom from '../Bottom'
import { Helmet } from 'react-helmet';
import './style.css'

class What_i_treat extends Component {
    render() {
        return (
            <div>
            <Helmet>
               <title>Послуги - Андрій Желтов, ЛОР лікар</title>
               <meta name="description" content="Після діагностики пацієнт отримує всі необхідні призначення, з урахуванням його індивідуальних особливостей. Для максимального ефекту консервативного лікування я здійснюю практично всі лікарські маніпуляції, які перерахувати повністю не можливо. Записуйся на консультацію" />
            </Helmet>
                <TopLine />
                <PhotoLine />
                <Menu />
                <div className="biography-page">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Головна</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Що я лікую</li>
                        </ol>
                    </nav>
                    <h3 className="text-center">Послуги або що я лікую</h3><br></br>
                    <p className = "text-justify"> Загальновідомо, що лор лікує вухо, горло і ніс. Безумовно, всі ці органи тісно взаємопов'язані між собою. І, звичайно, найбільш важливим моментом і для лікаря, і для пацієнта є встановлення правильного діагнозу. Адже саме в цьому запорука успішного лікування і одужання. </p>
                    <p className = "text-justify"> Не завжди для постановки діагнозу досить провести огляд. Вам можуть знадобитися додаткові дослідження: лабораторні аналізи, рентген, комп'ютерна томографія, функціональні проби, консультації суміжних фахівців. </p>
                    <p className = "text-justify"> Після діагностики пацієнт отримує всі необхідні призначення, з урахуванням його індивідуальних особливостей. Для максимального ефекту консервативного лікування я здійснюю практично всі лікарські маніпуляції, які перерахувати повністю не можливо. До них можна віднести:
                    <li className = "ml-5"> Вушні хвороби: промивання, туалет слухового проходу, видалення сірчаних пробок, сторонніх тіл, комах </li>
                        <li className = "ml-5"> Парацентез, введення лікарських препаратів, блокади та ін. </li>
                        <li className = "ml-5"> Захворювання носа: промивання порожнини носа і навколоносових пазух методом переміщення ("зозуля"), ЯМІК катетером, внутріносові блокади, проколи, дренування та ін. </li>
                        <li className = "ml-5"> Хвороби глотки: промивання мигдалин, масаж мигдалин, обколювання мигдалин, кріотерапія, тонзіллор і ін. </li>
                        <li className = "ml-5"> Захворювання гортані: блокади, внутрішньо-гортанні введення препаратів, инстиляції, лікування лазером і ін. </li> </p>
                    <p className = "text-justify"> Бувають випадки, коли консервативне лікування не ефективно і єдиним способом допомогти пацієнтові є хірургічне втручання. Я виконую всі види операцій в тому числі і при:
                    <li className = "ml-5"> Хворобах носа: септопластика, функціональна риносептопластика, турбінопластіка, різні види сінусотомій, фронтотомії драф 1,2,3, гайморотомія, видалення кіст верхньощелепних пазух, сфенотомія, видалення поліпів; операції на навколоносових пазухах проводяться, як правило без зовнішніх доступів (ендоназально) і ін. </li>
                        <li className = "ml-5"> Хворобах глотки: тонзіллотомію, тонзилектомії, аденоідотомія, розтин паратонзиллярного абсцесу. </li>
                        <li className = "ml-5"> Хвороби гортані: фіброми, папіломи, доброякісні утворення і ін. </li>
                    </p>
                </div>


                <Bottom />
            </div>

        );
    }
}

export default What_i_treat


