import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = { name: '', email: '', comment: '' };

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
  }

  onSubmit(event) {
    event.preventDefault()
    var data = {
      name: this.state.name,
      email: this.state.email,
      comment: this.state.comment,
      date: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()
    }
    


    fetch("https://api.backendless.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/data/comments", {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'cash-control':'no-cache'},
        body: JSON.stringify(data)
    }).then(function(response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.json();
    }).then(function(data) {

        if(data === "success"){
           this.setState({msg: "Thanks for registering"});  
        }
    }).catch(function(err) {
        console.log(err)
    });

    alert(`${this.state.name}, спасибо за отзыв!`);
    this.componentWillReceiveProps();

  }
  componentWillReceiveProps() {
    window.location.reload()
  }
  onChangeName(event) {
    this.setState({ name: event.target.value });
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }
  onChangeComment(event) {
    this.setState({ comment: event.target.value });
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <label htmlFor="InputName">Ім'я Прізвище</label>
          <input type="text" className="form-control" id="InputName" value={this.state.name} onChange={this.onChangeName} required></input>
        </div>
        <div className="form-group">
          <label htmlFor="InputEmail">Email</label>
          <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" className="form-control" id="InputEmail" value={this.state.email} onChange={this.onChangeEmail} required></input>
        </div>
        <div className="form-group">
          <label htmlFor="InputComment">Коментар</label>
          <textarea className="form-control" id="InputComment" rows="4" value={this.state.comment} onChange={this.onChangeComment} required></textarea>
        </div>
        <button className="review btn btn-yellow" type="submit">Відправити відгук</button>

      </form>
    )
  }
}


export default Comments