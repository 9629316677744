
import React, { Component } from 'react'
import './style.css'
import Moment from 'react-moment';




class CommentsView extends Component {
  componentWillMount() {  
    window.scrollTo(0, 0)
}
  constructor(props) {
    super(props);
    this.state = { comments: [] };

 
    fetch('https://api.backendless.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/data/comments',{
    headers: {'cash-control':'no-cache'}}
    )

        .then(response => response.json())
      .then(comments => (this.setState({ comments }))
        )


  }
  render() {


    return (
      <div className="commView">
        <h2 className="text-center text-uppercase"><strong>Відгуки</strong></h2>
        <div className="container commViewcont">

          <div className="col-md-8 offset-md-2 col-12">
          
            <div id="carouselExampleIndicators" className="carousel slide mt-5" data-ride="carousel">
              <div className="carousel-inner mt-4" >
                {this.state.comments.map((comment, index) => {
                  return index === 0 ?
                    <div className="carousel-item text-center active" key={index}>
                      <div className="img-box p-1 border rounded-circle mx-auto mb-2" style={{backgroundColor:"#44444c"}}>
                        <img className="d-block w-100 rounded-circle" style={{backgroundColor:"#44444c"}} src={require('./review.png')} alt="person" />
                      </div>
                      <h5 className="m-auto p-2"><strong className="text text-uppercase">{comment.name}</strong></h5>
                      <h6 className="m-0"> <Moment format="DD-MM-YYYY HH:mm:ss" >{comment.created}</Moment></h6>
                      <p className="m-0 pt-3">{comment.comment}</p>
                    </div>
                    :
                    <div className="carousel-item text-center " key={index}>
                      <div className="img-box p-1 border rounded-circle mx-auto mb-2" style={{backgroundColor:"#44444c"}}>
                        <img className="d-block w-100 rounded-circle" style={{backgroundColor:"#44444c"}} src={require('./review.png')} alt="person" />
                      </div>
                      <h5 className="m-auto p-2"><strong className="text text-uppercase">{comment.name}</strong></h5>
                      <h6 className="m-0"><Moment format="DD-MM-YYYY HH:mm:ss">{comment.created}</Moment></h6>
                      <p className="m-0 pt-3">{comment.comment}</p>
                    </div>
                })}
              </div>

              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default CommentsView