import React, { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import Commets from './Commets'
import Media from 'react-media'

function Bottom() {
    return (
        <Media queries={{
            small: "(max-width: 1024px)",
            medium: "(min-width: 1025px)"
        }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                        <div className="myBottom">
                            <div className="row text-center mx-auto">
                                <div className="col border-right border-white">
                                    <h2 className="text-center">Контакти</h2>
                                    <ul>
                                        <li className="list-unstyled">Київ </li>
                                        <li className="list-unstyled">Вінниця </li>
                                        <li className="list-unstyled">Запоріжжя </li>
                                        
                                    </ul>
                                    <div className="">
                                        <a href="https://instagram.com/doctor_zheltov?igshid=zf2xsrddmp4f"><img className="inst" src="https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/Logotip-instagram-t.png" alt="instagram" /></a>
                                    </div>
                                </div>
                                <div className="col">
                                    <h2 className="text-center">Залишити відгук</h2>
                                    <Commets />
                                </div>
                            </div>
                        </div>
                    }
                    {matches.medium &&
                        <div className="myBottom">
                            <div className="row text-center">
                                <div className="col border-right border-white">
                                    <h2 className="text-center">Контакти</h2>
                                    <ul>
                                       
                                    <li className="list-unstyled">Київ </li>
                                        <li className="list-unstyled">Вінниця </li>
                                        <li className="list-unstyled">Запоріжжя </li>
                                    </ul>
                                    <div className="">
                                        <a href="https://instagram.com/doctor_zheltov?igshid=zf2xsrddmp4f"><img className="inst" src="https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/Logotip-instagram-t.png" alt="instagram" /></a>
                                    </div>
                                </div>
                                <div className="col border-right border-white">
                                    <h2 className="text-center">Залишити відгук</h2>
                                    <Commets />
                                </div>
                                <div className="col m-auto">
                                    <button type="button" className="btn btn-yellow-outline font-weight-bolder shadow"><a href="tel:0667854854">Подзвонити</a> </button>
                                </div>
                            </div>
                        </div>
                    }
                </Fragment>
            )}
        </Media>


    )
}
export default Bottom