import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import { Link} from 'react-router-dom'

function TopLine() {
    return (
        <div className="container-fluid shadow">
            <div className="row align-items-center">
                <div className="col-5">
                <Link to="/" className="nav-link"><h1>Желтов Андрій Якович</h1></Link>
                </div>
                <div className="col-3 text-center">
                    <h3 className="font-weight-bold">ЛОР-лікар</h3>
                </div>
                <div className="col-4 text-right">
                    <button type="button" className="btn btn-yellow-outline font-weight-bolder shadow mx-auto"><a href="tel:0667854854">Подзвонити</a> </button>
                </div>
            </div>
        </div>
    )
}
export default TopLine