import React, { Component, Fragment } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import './style.css'
import Media from 'react-media'
import { Helmet } from 'react-helmet'



class Contacts extends Component {

    render() {
        return (
            <div>

                <Helmet>
                    <title>Контакти - Андрій Желтов, ЛОР лікар</title>
                    <meta name="description" content="Желтов Андрій Якович - лікар оториноларинголог, кандидат медичних наук, контактна інформація" />
                </Helmet>
                <TopLine />
                <PhotoLine />
                <Menu />

                <Media queries={{
                    small: "(max-width: 1024px)",
                    medium: "(min-width: 1025px)"
                }}>
                    {matches => (
                        <Fragment>
                            {matches.small &&
                                <div className="contact-page">
                                    <h3 className="text-center">Контактна інформація</h3><br></br>
                                    <div className="contact-jumbotron jumbotron text-center">
                                        <img className="img-fluid" src={require('./contacts.png')} alt="контакты" width="50%" />
                                        <h1 className="adres">Міста:</h1>
                                        <div className="row m-0">

                                            <div className="col p-1">
                                                <p className="lead">
                                                    <li>м. Київ
                                                    </li>
                                                    <li>м. Вінниця
                                                    </li>
                                                    <li>м. Запоріжжя
                                                    </li>
                                                </p>
                                            </div>

                                        </div>
                                        <div className="col">
                                            <a href="https://instagram.com/doctor_zheltov?igshid=zf2xsrddmp4f"><img className="inst" src="https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/Logotip-instagram-t.png" alt="instagram" /></a>
                                        </div>
                                        <hr className="my-4 bg-white shadow"></hr>
                                        <div className="text-center">
                                            <button type="button" className="btn btn-yellow-outline font-weight-bolder shadow mx-auto"><a href="tel:0667854854">Позвонить</a> </button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {matches.medium &&
                                <div className="contact-page">
                                    <h2 className="text-center">Контактна інформація</h2><br></br>
                                    <div className="contact-jumbotron jumbotron">
                                        <div className="row">
                                            <div className="col">
                                                <h1 className="adres">Міста:</h1>
                                                <p className="lead">

                                                    <li>м. Київ
                                                    </li>
                                                    <li>м. Вінниця
                                                    </li>
                                                    <li>м. Запоріжжя
                                                    </li>
                                                </p>
                                                <div className="col">
                                                    <a href="https://instagram.com/doctor_zheltov?igshid=zf2xsrddmp4f"><img className="inst" src="https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/Logotip-instagram-t.png" alt="instagram" /></a>
                                                </div>
                                            </div>
                                            <div className="col text-center">
                                                <img className="img-fluid" src={require('./contacts.png')} alt="контакты" />
                                            </div>
                                        </div>
                                        <hr className="my-4 bg-white shadow"></hr>
                                        <div className=" text-center">
                                            <button type="button" className="btn btn-yellow-outline font-weight-bolder shadow"><a href="tel:0667854854">Подзвонити</a> </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Fragment>
                    )}
                </Media>

            </div>

        );
    }
}



export default Contacts