import React, { Component, Fragment } from 'react'
import Media from 'react-media'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import MobileNav from './MobileNav'
import DesktopNav from './DesktopNav'



class Menu extends Component {
  render() {
    return (
      <div>
        <Media queries={{
          small: "(max-width: 1024px)",
          medium: "(min-width: 1025px)"
        }}>
          {matches => (
            <Fragment>
              {matches.small && <MobileNav/>}
              {matches.medium && <DesktopNav/>}
            </Fragment>
          )}
        </Media>
      </div>
    )
  }
}
  

export default Menu