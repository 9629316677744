import React, { Component } from 'react'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'


class Main extends Component {
    render() {
        return (
           
            <div className="container">
                <div className="card-horizontal">
                    <div className="card-img-left shadow col-4 m-auto">
                        <img className="img-fluid" src='https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/%D0%A3%D1%85%D0%BE%D0%93%D0%BE%D1%80%D0%BB%D0%BE%D0%9D%D0%BE%D1%81.png' alt="Card" />
                    </div>
                    <div className="card-body-fluid shadow p-3 col-8" style={{ backgroundColor: '#faf7dc', border:'1px solid #ffcc00', borderRadius: '1em', color: '#2b2b2f'}}>
                        <h3 className="card-title text-center">Послуги (що я лікую?)</h3>
                        <p className="card-text lead">Гострі і хронічні захворювання лор органів, функціонально-реконструктивні хірургічні втручання на них</p>
                        <a href="/main/what_i_treat" className="card-link" style={{ color: '#2b2b2f' }}>Детальніше</a>
                    </div>
                </div>
                <div className="card-horizontal">
                    <div className="card-body-fluid shadow p-3 col-8" style={{ backgroundColor: '#ffcc00', borderRadius: '1em', color: '#2b2b2f' }}>
                        <h3 className="card-title text-center">Устаткування і матеріали</h3>
                        <p className="card-text lead">Максимально функціональні і органозберігаючі підходи до хірургічного лікування із застосуванням сучасного відео-ендоскопічного, мікроскопічного обладнання та використанням сучасних витратних матеріалів.</p>
                        <a href="#" className="card-link" style={{ color: '#2b2b2f' }}>Детальніше</a>
                    </div>
                    <div className="card-img-right shadow col-4 m-auto">
                        <img className="img-fluid" src='https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/%D0%BE%D0%B1%D0%BE%D1%80%D1%83%D0%B4%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%D0%98%D0%BC%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%B0%D0%BB%D1%8B.png' alt="Card image" />
                    </div>
                </div>
                <div className="card-horizontal">
                    <div className="card-img-left shadow col-4 m-auto">
                        <img className="img-fluid" src="https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/%D0%BE%D0%B1%D0%BE%D1%80%D1%83%D0%B4%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%D0%98%D0%BC%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%B0%D0%BB%D1%8B.png" alt="Card image" />
                    </div>
                    <div className="card-body-fluid shadow p-3 col-8" style={{ backgroundColor:'#faf7dc', border:'1px solid #ffcc00', borderRadius: '1em', color: '#2b2b2f' }}>
                        <h3 className="card-title text-center">Технології</h3>
                        <p className="card-text lead">Можливість поєднання результатів сучасної діагностики з використанням новітнього хірургічного обладнання дозволяє досягти гарного результату лікування і звести до мінімуму страждання в післяопераційному періоді, знаходження в стаціонарі після операції.</p>
                        <a href="#" className="card-link" style={{ color: '#2b2b2f' }}>Детальніше</a>
                    </div>
                </div>
            </div>
        )

    }
}
export default Main