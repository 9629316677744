import React, { Component } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import Bottom from '../Bottom'
import './style.css'
import PhotoGrid from "react-photo-feed";
import { Helmet } from 'react-helmet';
import "react-photo-feed/library/style.css";


const demoPhotos = [
  {
    id: 1, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6913.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6913.jpg'

  },
  {
    id: 2, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6908.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6908.jpg'
  },
  {
    id: 3, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6912.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6912.jpg',
  },
  {
    id: 4, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6914.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6914.jpg',
  },
  {
    id: 5, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6916.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6916.jpg'
  },
  {
    id: 6, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6919.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6919.jpg',
  },
  {
    id: 7, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6920.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6920.jpg',
  },
  {
    id: 8, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6921.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6921.jpg',
  },
  {
    id: 9, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6923.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6923.jpg',
  }, {
    id: 10, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6924.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6924.jpg',
  },
  {
    id: 11, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6925.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6925.jpg',
  },
  {
    id: 12, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6926.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6926.jpg',
  },
  {
    id: 13, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6927.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6927.jpg',
  },
  {
    id: 14, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6917.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_6917.jpg',
  },
  {
    id: 15, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7005.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7005.jpg',
  },
  {
    id: 16, src: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7006.jpg',
    bigSrc: 'https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/IMG_7006.jpg',
  }
];


class Certificates extends Component {
  

  render() {
    return (
      <div>
        <Helmet>
          <title>Мої сертифікати - Андрій Желтов, ЛОР лікар</title>
          <meta name="description" content="Желтов Андрій Якович - лікар оториноларинголог, кандидат медичних наук, сертифікати" />
        </Helmet>
        <TopLine />
        <PhotoLine />
        <Menu />
        <div className="photo-page">
          <div className="container">
            <h3 className="text-center">Мої сертифікати</h3><br></br>
            <PhotoGrid columns={4} photos={demoPhotos} />
          </div>
        </div>
        <Bottom />
        
      </div>


    )
  }
}


export default Certificates
