import React, { Component } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import Bottom from '../Bottom'
import { Helmet } from 'react-helmet';
import './style.css'


class Operation extends Component {
    
    render() {
        return (
            <div >
              <Helmet>
               <title>Коли треба оперувати - Андрій Желтов, ЛОР лікар</title>
               <meta name="description" content="За 24 роки моєї лікарської практики, мені вдалося допомогти тисячам пацієнтів вилікуватися від хронічних лор - захворювань саме шляхом оперативного втручання. Під час консультації, я завжди намагаюся докладно і наочно пояснювати суть проблеми пропоную, що можна зробити, чому, і яке обладнання буде при цьому використовуватися." />
            </Helmet>
                <TopLine />
                <PhotoLine />
                <Menu />
                <div className="biography-page">
                    <h3 className="text-center">Коли треба оперувати?</h3><br></br>
                    <p className="text-justify">
                        Загальновідомо, що люди, як правило, з деяким побоюванням ставляться до операцій і завжди намагаються їх відкласти, якщо це можливо. Іноді вони вважають за краще лікувати роками свої хронічні хвороби, пов'язані з лор-органами, тимчасово полегшуючи свій стан. А між тим, час іде, проблема залишається, а якість життя знижується. </p>
                    <p className="text-justify"> Тому, для того, щоб людина могла жити повним життям і назавжди позбутися від своїх проблем, іноді єдиний вихід - це операція. </p>
                    <p className="text-justify"> За 24 роки моєї лікарської практики, мені вдалося допомогти тисячам пацієнтів вилікуватися від хронічних лор - захворювань саме шляхом оперативного втручання. Під час консультації, я завжди намагаюся докладно і наочно пояснювати суть проблеми пропоную, що можна зробити, чому, і яке обладнання буде при цьому використовуватися. Жодне ваше запитання не повинно залишитися без відповіді! </p>
                    <p className="text-justify"> І, одне з головного - володіння і можливість використовувати високий технологічний рівень лор-обладнання в операційній потребують від вас всього декількох годин перебування в лікарні! Я можу запропонувати вам сучасні методики проведення операцій: безболісні, органо-зберігаючі, з мінімальним ризиком ускладнень. </p>
                    <p className="text-justify"> Пам'ятайте, вибір завжди залишається за вами - якісне і комфортне життя протягом багатьох років залежить від правильного вибору. </p>
                </div>
                <Bottom />
                
            </div>

        );
    }
}



export default Operation