import React, { Component } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import Bottom from '../Bottom'
import './style.css'
import "react-photo-feed/library/style.css";




class GalleryPhoto extends Component {
  
  render() {
    return (
      <div>
        <TopLine />
        <PhotoLine />
        <Menu />
        <div className="photo-page">
          <div className="container">
            <h3 className="text-center">Фото</h3><br></br>

          </div>
        </div>
        <Bottom />
        
      </div>


    )
  }
}



export default GalleryPhoto
