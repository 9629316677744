import React, {Fragment} from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import Media from 'react-media'

function PhotoLine() {
    var date = new Date();
    const years = date.getFullYear() - 1996;
    var Year = '';
    if (years % 10 >= 1 && years % 10 < 5) {
        Year = "роки"
    }
    else {
        Year = "років"
    }
    return (
        <div className="photoLine">
            <div className="container">
                <div className="card img">
                    <Media queries={{
                        small: "(max-width: 1024px)",
                        medium: "(min-width: 1025px)"
                    }}>
                        {matches => (
                            <Fragment>
                                {matches.small && <img src="https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/%D0%96%D0%90%D0%AF%C2%A0%E2%80%94+%D0%BC%D0%BE%D0%B1+%D0%BA%D0%BE%D0%BF%D0%B8%D1%8F.png" className="icard-img img-fluid" alt="Желтов" />}
                                {matches.medium && <img src="https://backendlessappcontent.com/1E3EF7DD-C423-7469-FFCB-AC401C60F200/96088773-9EA1-422B-8A5E-CAF926EE72CB/files/images/%D0%96%D0%90%D0%AF+%D0%BA%D0%BE%D0%BF%D0%B8%D1%8F.png" className="icard-img img-fluid" alt="Желтов" />}
                            </Fragment>
                        )}
                    </Media>
                    <div className="card-img-overlay pl-0">
                        <h3 className="card-title ">Вітаю!</h3>
                        <p className="card-text lead">Працюю лор лікарем {years} {Year}.  Кандидат медичних наук. Більше 10 років завідую лор-відділенням, де надаю лор допомогу дорослим і дітям. Якщо ви потребуєте моєї професійної допомоги, готовий поділитися своїм досвідом в діагностиці, а при необхідності і в лікуванні.</p>
                    </div>
                </div>
            </div>

        </div>

    )
}
export default PhotoLine