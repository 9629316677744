import React, { Component } from 'react'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import { Link} from 'react-router-dom'


class MobileNav extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <header className="col-md-3">
                        <nav className="sidebar-sticky navbar-light navbar-expand-xl">
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto flex-column">
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">Головна</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Пацієнту</a>
                                        <div className="dropdown-menu">
                                            <Link to="/patient/commoninfo" className="dropdown-item">Загальна інформація</Link>
                                            <Link to="/patient/operation" className="dropdown-item">Потрібно оперувати</Link>
                                            <Link to="/patient/nooperation" className="dropdown-item">Лікування без операцій</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Мої роботи</a>
                                        <div className="dropdown-menu">
                                            <Link to="/myworks/photo" className="dropdown-item" href="#">&nbsp;&nbsp;&nbsp;&nbsp;Фото&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                                            <Link to="/myworks/video" className="dropdown-item" href="#">&nbsp;&nbsp;&nbsp;&nbsp;Відео&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Про мене</a>
                                        <div className="dropdown-menu">
                                            <Link to="/aboutMe/biography" className="dropdown-item">Біографія</Link>
                                            <Link to="/aboutMe/mycertificates" className="dropdown-item" href="#">Мої сертифікати</Link>
                                            <Link to="/aboutMe/scientificActivity" className="dropdown-item" href="#">Наукова діяльність</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/Contacts" className="nav-link">Контакти</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/reviews" className="nav-link">Відгуки</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </header>
                </div>
            </div>
        )
    }
}

export default MobileNav