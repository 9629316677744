import React, { Component } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import Bottom from '../Bottom'
import './style.css'


class Video extends Component {
    render() {
        return (
            <div >
                <TopLine />
                <PhotoLine />
                <Menu />
                <div className="biography-page">
                    <div className="container">
                    </div>
                </div>
                <Bottom />
                
            </div>

        );
    }
}



export default Video