import React, { Component } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import Bottom from '../Bottom'
import './style.css'
import { Helmet } from 'react-helmet';


class CommonInfo extends Component {
    

    render() {
        return (
            <div>
                <Helmet>
                    <title>Пацієнту, загальна інформація - Андрій Желтов, ЛОР лікар</title>
                    <meta name="description" content="Приходьте на консультацію! Ми разом з вами обговоримо вашу проблему. Я думаю, що зможу розібратися і доступно пояснити вам, чим можна допомогти. Пам'ятайте, вибір завжди залишиться за вами, але тільки особиста зустріч і спілкування з лікарем, розуміння проблеми після консультації допоможуть вам прийняти правильне рішення." />
                </Helmet>
                <TopLine />
                <PhotoLine />
                <Menu />
                <div className="biography-page">
                    <h3 className="text-center">Загальна інформація</h3><br></br>
                    <p className="text-justify">
                        Не секрет, що, коли з'являються проблеми зі здоров'ям, неможливо думати ні про що інше. </p>
                    <p className="text-justify"> В цей момент, найголовніше - знайти лікаря, який зможе допомогти вам. Це не просто, тому, що вам належить зробити вибір. А адже найважливіше - це ступінь довіри, яку ви відчуваєте до свого лікаря. </p>
                    <p className="text-justify"> Як вчинити? З ким порадитися? Як розібратися в тому різноманітті інформації, яка представлена ​​на просторах інтернету? <br></br> Адже це ваше здоров'я, ваше життя! </p>
                    <p className="text-justify"> Приходьте на консультацію! Ми разом з вами обговоримо вашу проблему. Я думаю, що зможу розібратися і доступно пояснити вам, чим можна допомогти. Пам'ятайте, вибір завжди залишиться за вами, але тільки особиста зустріч і спілкування з лікарем, розуміння проблеми після консультації допоможуть вам прийняти правильне рішення. </p>
                    <p className="text-justify"> Я впевнений - мій багаторічний лікарський досвід, мої глибокі знання і щире бажання допомогти обов'язково стануть запорукою вашого одужання!

                    </p>
                </div>
                <Bottom />
                
            </div>

        );
    }
}


export default CommonInfo