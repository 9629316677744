import React, { Component } from 'react'
import TopLine from './TopLine'
import PhotoLine from './PhotoLine'
import Menu from './Menu'
import Interesting from './Interesting';
import Bottom from './Bottom'
import Main from './Main'
import '../components/App.css'
import { Helmet } from 'react-helmet';


class App extends Component {
   


   render() {
      return (

         <div >
            <Helmet>
               <title>Андрій Желтов, ЛОР лікар</title>
               <meta name="description" content="Желтов Андрій Якович - кандидат медичних наук. Більше 10 років завідую лор-відділенням, де надаю лор допомогу дорослим і дітям. Якщо ви потребуєте моєї професійної допомоги, готовий поділитися своїм досвідом в діагностиці, а при необхідності і в лікуванні." />
            </Helmet>
            <TopLine />
            <PhotoLine />
            <Menu />
            <Main />
            <Interesting />
            <Bottom />
            
         </div>

      );
   }
}


export default App