import React, { Component } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import Bottom from '../Bottom'
import './style.css'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'


class Biography extends Component {
    

    render() {
        return (
            <div>
            <Helmet>
               <title>Біографія - Андрій Желтов, ЛОР лікар</title>
               <meta name="description" content="Желтов Андрій Якович - лікар оториноларинголог, кандидат медичних наук, маю вищу категорію по оториноларингології і вищу категорію по дитячій отоларингології, завідую лор відділенням " />
            </Helmet>
                <TopLine />
                <PhotoLine />
                <Menu />
                <div className="biography-page">

                    
                    <h3 className="text-center">Моя Біографія</h3><br></br>
                    <p className="text-justify">Я - лікар оториноларинголог, кандидат медичних наук, маю вищу категорію по оториноларингології і вищу категорію по дитячій отоларингології, завідую лор відділенням у TOP Clinic Denis, м. Київ. <br></br><br></br>
                            Працюю лікарем отоларингологом з 1996 року після закінчення навчання в Запорізькому державному медичному університеті (ЗДМУ), медичний факультет, спеціальність - лікувальна справа. <br></br> <br></br>
                            Після закінчення інтернатури на базі Запорізької міської лікарні №3 (керівники завідувач кафедрою, професор, д. Мед. Н. Троян В.І. і завідуюча відділенням Лісіцина Л.Я.) працював лор лікарем Василівської ЦРЛ. <br></br> <br></br>
                            З 1998р по 2017 працював лор лікарем консультантом Запорізької обласної інфекційної лікарні. З 2001 по 2019 ургентними лор лікарем в Запорізькій міській лікарні екстреної і швидкої медичної допомоги (КНП "ГБЕ і СМП"). <br></br> <br></br>
                            У 2001 зарахований до клінічної ординатури ЗДМУ під керівництвом професора, д. Мед. н. Трояна В.І. основне захоплення диференціальна діагностика туберкульозу і раку гортані у хворих, які страждають на туберкульоз легенів. Після успішного закінчення в 2003 році прийнятий лікарем ординатором в ЛОР - відділення 3 міської клінічної лікарні. <br></br> <br></br>
                            З 2008 року по 2024 рік, отримавши вищу категорію по отоларингології, працюю завідувачем ЛОР відділення стаціонару багатопрофільної лікарні Вітацентр. У 2008 пройшов "Спеціалізація з дитячої оториноларингології". НМПО ім. П.Л.Шупика Київ і з 2013 року маю вищу категорію по дитячій отоларингології. <br></br> <br></br>
                            Лор відділення Вітацентр надає спеціалізовану лор допомогу, як дітям, так і дорослим практично будь-якого рівня. В оснащенні відділення має і регулярно використовує в своїй роботі повноцінне ендоскопічне, мікроскопічне обладнання, різні види лазерів, коблацію і ін. <br></br> <br></br>
                            З 2015 року заочно навчався і успішно закінчив аспірантуру в 2019 при Запорізькій медичній академії післядипломної освіти (ЗМАПО) <br></br>
                            У 2020р захистив КМД. На тему Діагностика и лікування захворювань лімфоїдної тканини носоглотки у дітей з ексудативно середнім отитом Науковий керівник: доктор медичний наук, професор Гусаков Олександр Дмитрович, (особлива подяка) <br></br> <br></br>
                            З 2024 року працюю завідувачем ЛОР відділення у TOP Clinic Denis у м. Київ.
                            У міру можливостей проходжу стажування та вдосконалення своїх практичних і теоретичних навичок як в Україні, так і за її межами про що із задоволенням ділюся зі своїми колегами
                            <Link className="certificates p-0" to="/aboutMe/mycertificates"> див.сертифікати</Link> <br></br>
                    </p>

                </div>


                <Bottom />
            </div>

        );
    }
}

export default Biography



