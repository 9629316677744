import React, { Component } from 'react'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'
import { Link, Router } from 'react-router-dom'


class DesktopNav extends Component {
  render() {
    return (

      <ul className="nav justify-content-center">
        <li className="nav-item">
          <Link to="/" className="nav-link">Головна</Link>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Пацієнту</a>
          <div className="dropdown-menu">
            <Link to="/patient/commoninfo" className="dropdown-item">Загальна інформація</Link>
            <Link to="/patient/operation" className="dropdown-item">Потрібно оперувати</Link>
            <Link to="/patient/nooperation" className="dropdown-item">Лікування без операцій</Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Мої роботи</a>
          <div className="dropdown-menu">
            <Link to="/myworks/photo" className="dropdown-item" href="#">Фото</Link>
            <Link to="/myworks/video" className="dropdown-item" href="#">Відео</Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Про мене</a>
          <div className="dropdown-menu">
            <Link to="/aboutMe/biography" className="dropdown-item">Біографія</Link>
            <Link to="/aboutMe/mycertificates" className="dropdown-item" href="#">Мої сертифікати</Link>
            <Link to="/aboutMe/scientificActivity" className="dropdown-item" href="#">Наукова діяльність</Link>
          </div>
        </li>
        <li className="nav-item">
          <Link to="/Contacts" className="nav-link">Контакти</Link>
        </li>
        <li className="nav-item">
          <Link to="/reviews" className="nav-link">Відгуки</Link>
        </li>
      </ul>



    )
  }
}

export default DesktopNav