import React, { Component } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import Bottom from '../Bottom'
import { Helmet } from 'react-helmet';
import './style.css'


class Nooperation extends Component {
    
    render() {
        return (
            <div >
             <Helmet>
               <title>Лікування без операцій - Андрій Желтов, ЛОР лікар</title>
               <meta name="description" content="Необхідно уважно проаналізувати загальний стан людини, як давно вона має ту чи іншу проблему. У кожному конкретному випадку потрібно врахувати дуже багато факторів - вік, спосіб життя, професію і навіть емоційний стан. І, перш ніж дати свої рекомендації, я завжди детально пояснюю пацієнтові всі можливі варіанти лікування, їх тривалість і очікувані результати. Записуйся на консультацію до лор лікаря Андрія Желтова" />
            </Helmet>
                <TopLine />
                <PhotoLine />
                <Menu />
                <div className="biography-page">
                    <h3 className="text-center">Лікування без операцій</h3><br></br>

                    <h5 className="text-justify">
                        Чи можливо вилікуватися без операції?</h5>
                    <p className="text-justify">Це одне з найпоширеніших питань, які мені задають пацієнти. Відповідь не завжди може бути однозначною. Необхідно уважно проаналізувати загальний стан людини, як давно вона має ту чи іншу проблему. У кожному конкретному випадку потрібно врахувати дуже багато факторів - вік, спосіб життя, професію і навіть емоційний стан. І, перш ніж дати свої рекомендації, я завжди детально пояснюю пацієнтові всі можливі варіанти лікування, їх тривалість і очікувані результати.</p>
                    <p className="text-justify">Хочу дати щиру пораду - не затягуйте звернення до лікаря, якщо у вас виникла проблема! Адже чим раніше доктор почне лікування, тим більше у вас шансів обійтися без операції і швидше одужати.</p>
                    <p className="text-justify">Робіть свій вибір! І пам'ятайте, що тільки особиста зустріч і безпосереднє спілкування з лікарем допоможе вам прийняти правильне рішення на користь вашого здоров'я, а повна довіра між лікарем і його пацієнтом - це основа успішного лікування! Бережіть себе і будьте здорові!</p>
                </div>
                <Bottom />
                
            </div>

        );
    }
}



export default Nooperation