import React, { Component } from 'react'
import TopLine from '../TopLine'
import PhotoLine from '../PhotoLine'
import Menu from '../Menu'
import './style.css'
import CommentsView from '../CommentsView'
import Comments from '../Bottom/Commets'
import { Helmet } from 'react-helmet';

class Reviews extends Component {
    
    render() {
        return (
            <div>
            <Helmet>
               <title>Відгуки - Андрій Желтов, ЛОР лікар</title>
               <meta name="description" content="Відгуки пацієнтів лор лікаря, кандидата медичних наук Желтова Андрія " />
            </Helmet>
                <TopLine />
                <PhotoLine />
                <Menu />
                <CommentsView />
                <div className="myBottom">
                    <div className="container p-2">
                        <h2 className="text-center">Залишити свій відгук</h2>
                        <Comments />
                    </div>
                </div>
                
            </div>

        );
    }
}



export default Reviews